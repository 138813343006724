<template>
  <section class="mainText ">
    <b-container fluid="xl">
        <b-row class="maintText__row">
        <b-col cols="12" class="mainText__mainName side-gap">

          <h2 style="mainText__mainName--maintxtx">
            Anonimitás 
          </h2>
          <p class="hashText">- egyenlőség vagy a trollok fellegvára?</p>
        </b-col>
      </b-row>

      <b-row class="maintText__row">
        <b-col cols="12" lg="6" class="side-gap order-0">
          <div class="maintText__txt-content">
            <p class="text">
              Ki ne szeretne néha láthatatlanná válni, következmények nélkül belesni mások életébe, csínyeket elkövetni vagy éppen elmondani a véleményét úgy, hogy nem kell félnie a büntetéstől? Míg régen ezek szokatlan és irreális, gyermeteg vágyak voltak és voltaképpen csak a felelősségvállalás elől jelentettek egérutat, addig az internet világában ez már nemcsak lehetséges, de kifejezetten mindennapos.  
            </p>
            <p class="text pb-5">Még csak nem is kell hozzá láthatatlanná tévő köpeny: az interneten bárki regisztrálhat különböző oldalakra, ahol nem is kérnek személyes adatokat, vagy ha mégis, nem ellenőrzik azok igazságtartalmát. Így nem csupán arctalanul, de olykor hamis arccal is megjelenhetünk a közösségi térben, karöltve annak pozitív és negatív hozadékaival. 
            <br><br>Mit is ad nekünk a névtelenség? 
            </p>
              
          </div>
        </b-col>
        <b-col cols="12" lg="6" class="side-gap order-4 order-lg-1">
            <img src="@/assets/images/bgr/anonym.jpg" class="w-100 margin-top-sm-44"/>
        </b-col>
        <b-col cols="12" lg="6" class="side-gap order-lg-2 order-1">
            <p class="text">
              <ul>
                <li class="py-3 d-sm-flex">
                  <span class="number">1</span>
                  <p class="text"><b>Online személyiség, azaz e-personality:</b> az interneten nárcisztikusabbak, impulzívabbak, agresszívebbek vagyunk, összefoglalóan gyermetegebbek. Az online tér hajlamosít arra, hogy levetkőzzük felnőtt személyiségünk jól szocializált részeit, és gátlások nélkül, felszabadultan cselekedjünk.</p> 
                </li>
                <li class="py-3 d-sm-flex">
                  <span class="number">2</span>
                  <p class="text"><b>Szólásszabadság:</b> korábbi elvárásoktól mentesen szólalhatunk fel, határokat feszegethetünk, anélkül, hogy valaha is a szemébe néznénk a másik embernek. Ez jelentheti azt, hogy az elfojtott véleményünket vállaljuk fel, de azt is, hogy ezt kihasználva szándékosan hergelünk valakit következmények nélkül. </p>
                </li>
                <li class="py-3 d-sm-flex">
                  <span class="number">3</span>
                  <p class="text"><b>Biztonságérzet:</b> az internetbiztonság egy rendkívül fontos tényező, amit mindenkinek alaposan meg kell tanulnia, de egy biztos: kevés kéz nyúlt ki a számítógépből, hogy felpofozza a gyanútlan internetezőt. Ez a hamis biztonságérzet azonban vezethet információ vagy képek kiszivárgásához, lejárató hadjáratokhoz vagy éppen kiürített bankszámlához.</p>
                </li>
              </ul>
            </p>
        </b-col>
        <b-col cols="12" lg="6" class="side-gap order-lg-3 order-2">
            <p class="text">
              <ul>
                <li class="py-3 d-sm-flex">
                  <span class="number">4</span>
                  <p class="text"><b>Bizalmasság:</b> gyorsan ki tud alakulni egy intim kapcsolat képe. Serdülőknél jellemző az online kapcsolattartásra nagyobb hajlandóság, olyan témák bevitele a beszélgetésbe, amit élőben nem hoznának fel, hiszen szégyellik azt, félnek a lehetséges következményektől. </p>
                </li>
                <li class="py-3 d-sm-flex">
                  <span class="number">5</span>
                  <p class="text"><b>Személyes jelenlét hiánya:</b> a biztonságérzeten túl a tény, hogy az internetes cselekedeteink nem a fizikai térben történnek, egyúttal bátrabbá, sőt, agresszívabbá is tehet bennünket. </p>
                </li>
              </ul>
            </p>
        </b-col>
      </b-row>
    </b-container>
  <Articles :isFirst="false"/>
  </section>
</template>
<script>
import Articles from '../components/Articles.vue'

export default {
  components: {
    Articles
  },
  mounted(){

    this.setMetaTags({        
        title: "Anonimitás - egyenlőség vagy a trollok fellegvára?", 
        desc: "Ki ne szeretne néha láthatatlanná válni, következmények nélkül belesni mások életébe, csínyeket elkövetni vagy éppen elmondani a véleményét úgy, hogy nem kell félnie a büntetéstől?", 
        keywords: null, 
        ogType: 'website', 
        ogTitle: "Anonimitás - egyenlőség vagy a trollok fellegvára?",
        ogImage: null, 
        ogImageWidth: null, 
        ogImageHeight: null, 
        ogDesc: "Ki ne szeretne néha láthatatlanná válni, következmények nélkül belesni mások életébe, csínyeket elkövetni vagy éppen elmondani a véleményét úgy, hogy nem kell félnie a büntetéstől?"        
    })
  }
}
</script>